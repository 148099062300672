/**
 * Created by gurbir.dhillon on 2017-07-12.
 */
/**
 - * Load Polyfill for IE
 - */
require("@babel/polyfill");
require("@babel/plugin-proposal-object-rest-spread");

import $ from "jquery";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { Dropzone } from "dropzone";

window.$ = window.jQuery = $;
window.AniJS = require("./libs/anijs");
window.Cookies = require("js-cookie");
window.axios = axios;
window._ = _;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.moment = moment;
window.Clipboard = require("clipboard");
require("hammerjs");
require("foundation-sites/dist/js/foundation.min");
require("inputmask/dist/jquery.inputmask.js");
require("select2");
require("clientjs");

window.Highcharts = require("highcharts");
require("highcharts/modules/drilldown")(Highcharts);

window.sprintf = require("sprintf-js").sprintf;
window.vsprintf = require("sprintf-js").vsprintf;

$(document).foundation();

// Setup Vue
import Vue from "vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import VueResource from "vue-resource";
import VueAnalytics from "vue-analytics";
import { configure } from "vee-validate";

/**
 * Vue is a modern JavaScript for building interactive web interfaces using
 * reacting data binding and reusable components. Vue's API is clean and
 * simple, leaving you to focus only on building your next great idea.
 */
window.Vue = Vue;
window.Vuex = Vuex;
window.VueRouter = VueRouter;
window.VueResource = VueResource;
window.VueAnalytics = VueAnalytics;

Vue.use(VueResource);
Vue.use(VueRouter);
Vue.use(Vuex);

const config = {
    classes: {
        valid: "is-valid-input", // model is valid
        invalid: "is-invalid-input", // model is invalid
    },
    mode: "eager",
};

configure(config);

require("./libs/date-range-picker");
require("./libs/jquery.sumoselect");
require("./libs/redactor/redactor");
window.Dropzone = Dropzone;
